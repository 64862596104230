import Navbar from "../../../components/custom/navbar";
import "./styles.scss";
import Footer from "../../../components/custom/footer";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import Location from "../../../components/icons/location";
// import StarIcon from "../../../components/icons/star";
import ChevronRight from "../../../components/icons/chevron-right";
import Minusicon from "../../../components/icons/minus-icon";
import Plusicon from "../../../components/icons/plus-icon";
import Emailicon from "../../../components/icons/email";
import Maps from "../../../assets/images/maps.png";
import WhatsappWhite from "../../../components/icons/whatsapp-white";
import Pills from "../../../components/custom/pills";
// import ReviewsCard from "../../../components/custom/reviews-card";
import HostUserCard from "../../../components/custom/host-user-card";
import SimilarListing from "../../../components/custom/similar-listing";
import ArrowTopRight from "../../../components/icons/arrow-top-right";
import ArrowTopRightBlack from "../../../components/icons/arrow-top-right copy";
import { ImageGridStatic } from "../../../components/custom/image-slider";
import Award from "../../../components/icons/award";
import Carousel from "../../../components/custom/carousel";
import Modal from "../../../components/custom/modal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProperty,
  getSimilarProperties,
  setProperty,
} from "../../../redux/reducers/property-slice";
import {
  fetchSimilarProperty,
  fetchSingleProperty,
} from "../../../redux/actions/property";
import { Link, useParams } from "react-router-dom";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import { currencyFormat, truncate } from "../../../utils/helper-functions";
import { RWebShare } from "react-web-share";
import BreadcrumbsTwo from "../host-dashboard/components/breadcrumb-two";
import { paths } from "../../../routes/paths";
import DatePicker from "react-datepicker";
import moment from "moment";
import useQuery from "../../../hooks/use-query";
import { addDays, eachDayOfInterval, getDay } from "date-fns";
import { usePropertyForm } from "../host-onboard/hooks/use-property-form";
import SpecialReservations from "../../../components/custom/special-reservations";
import PillsTwo from "../../../components/custom/pills-two";
import { getIconComponent } from "../../../utils/get-icons";
import ScrollTop from "../../../components/custom/scroll-to-top";
import MapView from "../../../components/custom/map-view-location";
import { Helmet } from "react-helmet";

type ValuePiece = Date;

const SkeletonBar = () => (
  <div className="animate-pulse w-full my-2">
    <div className="h-2 bg-gray600 rounded w-1/2"></div>
  </div>
);

const ViewProperty = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const query = useQuery();
  const isLoading = useSelector(getIsLoading);
  const property = useSelector(getProperty);
  const similarProperties = useSelector(getSimilarProperties);
  const [isOpen, setOpen] = useState(false);
  const [countChildren, setCountChildren] = useState(0);
  const [countAdult, setCountAdult] = useState(1);
  const { propertyCategories } = usePropertyForm();
  const [checkinDate, setCheckinDate] = useState<ValuePiece>(new Date());
  const [checkoutDate, setCheckoutDate] = useState<ValuePiece>(
    addDays(new Date()?.toISOString(), 1) || new Date()
  );
  const [isDateOpen, setIsDateopen] = useState("hidden");
  const [warning, setWarning] = useState("");
  const [noOfDays, setNoDays] = useState(0);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSingleProperty(id));
    // @ts-ignore
    dispatch(fetchSimilarProperty(id));
    return () => {
      dispatch(setProperty({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // @ts-ignore
      dispatch(fetchSimilarProperty(property.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.id]);

  useEffect(() => {
    if (query.get("checkinDate")) {
      // @ts-ignore
      setCheckinDate(new Date(query.get("checkinDate")));
    }
    if (query.get("checkoutDate")) {
      // @ts-ignore
      setCheckoutDate(new Date(query.get("checkoutDate")));
    }
    if (query.get("guests")) {
      setCountAdult(Number(query.get("guests")));
    }
  }, [query]);

  const chargeNights = useMemo(() => {
    const dayin = moment(checkinDate?.toISOString());
    const dayOut = moment(checkoutDate?.toISOString());
    const diffDays = dayOut.diff(dayin, "days");
    setNoDays(diffDays <= 0 ? 1 : diffDays);
    return diffDays <= 0
      ? Number(property.price)
      : diffDays * Number(property.price);

    // eslint-disable-next-line
  }, [checkinDate, checkoutDate, property]);

  const serviceFee = useMemo(() => {
    if (property.service_fee_type === "percentage") {
      return chargeNights * Number(property.service_fee);
    } else {
      return Math.ceil(Number(property.service_fee));
    }

    // eslint-disable-next-line
  }, [property, chargeNights]);
  const totalAmount = useMemo(
    () => chargeNights + serviceFee,

    // eslint-disable-next-line
    [chargeNights, serviceFee]
  );

  const getAvaliableDays = useMemo(
    () => [
      property.monday_availability ? null : 1,
      property.tuesday_availability ? null : 2,
      property.wednesday_availability ? null : 3,
      property.thursday_availability ? null : 4,
      property.friday_availability ? null : 5,
      property.saturday_availability ? null : 6,
      property.sunday_availability ? null : 0,
    ],

    // eslint-disable-next-line
    [property]
  );

  const customDisabledDates = property?.blocked_out_dates?.map(
    (item) => new Date(item)
  );

  const isWeekdayDisabled = useMemo(
    () => (date: any) => {
      const day = getDay(date);
      const disableAvailableDays = getAvaliableDays
        .filter((item) => item !== null)
        .some((disableDay) => day === disableDay);
      return disableAvailableDays; // Disable days and custom dates
    },
    // eslint-disable-next-line
    [property, checkinDate, checkoutDate, getAvaliableDays]
  );

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setWarning("");
    setCheckinDate(start);
    setCheckoutDate(end);
    if (start && end) {
      const allDates = eachDayOfInterval({ start, end });
      const hasDisabledDate = allDates.some(isWeekdayDisabled);
      if (hasDisabledDate) {
        setWarning(
          "Warning: The selected date range includes a disabled date."
        );
      } else {
        setWarning("");
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>{property?.name || "Property"}</title>
        <meta
          name="description"
          content={property?.name || "Property description"}
        />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <ScrollTop />
      <div className="bg-eadWhite dark:bg-gray1000 relative pb-18 px-4">
        <div className="max-w-screen-2xl mx-auto">
          <Navbar />
        </div>
        <div className="max-w-screen-lg mx-auto lg:pt-16">
          <div className="flex items-center justify-between">
            <BreadcrumbsTwo
              name={property?.name}
              path={`${paths.VIEW_PROPERTY}/${property?.slug}`}
            />

            <div className="flex gap-2 items-center">
              <RWebShare
                data={{
                  text: property?.name,
                  url: property?.share_link,
                  title: property?.name,
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <button className="text-gray1000 dark:text-gray300 lg:bg-gray400 dark:bg-gray900 flex items-center gap-1 rounded-full px-2 h-8">
                  <span className="hidden lg:block text-xs font-semibold">
                    Share
                  </span>
                  <ArrowTopRightBlack className="w-4 lg:2" />
                </button>
              </RWebShare>
            </div>
          </div>

          <hr className="border-gray400 dark:border-gray800  my-6" />

          {/* images */}
          <ImageGridStatic
            // @ts-ignore
            images={property?.property_media}
            seeAllactions={() => setOpen(true)}
          />

          <div className="relative lg:hidden xl:hidden rounded-xl overflow-hidden">
            <div className="flex items-center justify-between absolute top-4 z-50 inset-x-1">
              <div className="flex gap-1 rounded-full bg-primary100 items-center p-1">
                <Award />
                <span className="text-xs font-display text-primary500">
                  Rare find
                </span>
              </div>
            </div>
            <Carousel
              // @ts-ignore
              slides={property?.property_media || []}
              classNameImg="rounded-xl object-cover w-full h-48"
            />
          </div>

          <div className="lg:flex items-start justify-center my-4">
            <div className="lg:w-2/3 flex flex-col mr-3">
              <div className="lg:hidden flex items-center justify-between">
                <div className="flex gap-1 items-center my-2">
                  <p className="text-2xl font-display font-bold text-gray1000 dark:text-gray200 ">
                    {currencyFormat(property?.price || 0)}
                  </p>
                  <p className="text-xs font-display dark:text-gray200">
                    per night
                  </p>
                </div>
                <ArrowTopRight />
              </div>

              {property?.name ? (
                <p className="lg:text-xl text-base font-display text-gray1000 dark:text-gray200 font-bold mb-2">
                  {property?.name}
                </p>
              ) : (
                <SkeletonBar />
              )}
              {/* <div className="flex gap-1 items-center mb-1">
                <StarIcon />
                <p className="text-xs font-display text-gray900 dark:text-gray200 ">
                  4.50 - 4 reviews
                </p>
              </div> */}
              <div className="flex gap-1 items-center">
                <Location />
                {property?.address ? (
                  <p className="text-xs font-display text-gray900 dark:text-gray200 ">
                    {property?.address}
                  </p>
                ) : (
                  <SkeletonBar />
                )}
              </div>
              <hr className="border-gray400 my-3 dark:border-gray800 " />
              <div className="flex gap-2 items-end md:items-center justify-between md:px-0 px-2 py-4 fixed bottom-0 inset-x-2 lg:hidden bg-gray200 dark:bg-gray1000 z-50">
                <div className="flex md:hidden flex-col">
                  <p className="font-display text-base text-gray1000 font-medium dark:text-gray200">
                    Total
                  </p>{" "}
                  <p className="font-display text-base text-gray900 font-bold dark:text-gray200">
                    {currencyFormat(totalAmount || 0)}
                  </p>{" "}
                </div>
                <div className="flex items-center gap-2 md:justify-between">
                  {" "}
                  <Link
                    to={`mailto:${
                      property?.host?.email
                    }?subject=Bookings&body=${encodeURIComponent(
                      `Hello i want to book this property ${
                        property?.share_link
                      }?guests=${countAdult}&checkinDate=${moment(
                        checkinDate
                      ).format("YYYY-MM-D")}&checkoutDate=${moment(
                        checkoutDate
                      ).format("YYYY-MM-D")}`
                    )}`}
                    className="md:w-[40%] w-fit md:px-0 px-3 flex font-display gap-2 items-center justify-center bg-gray400 font-bold p-1 rounded-full shadow-sm text-xs dark:bg-gray1000 dark:text-gray200 dark:border-gray800 dark:border"
                  >
                    <Emailicon />
                    <span> Email</span>
                  </Link>
                  <Link
                    to={`https://wa.me/${
                      process.env.REACT_APP_WHATSAPP
                    }?text=${encodeURIComponent(
                      `Hello i want to book this property ${
                        property?.share_link
                      }?guests=${countAdult}&checkinDate=${moment(
                        checkinDate
                      ).format("YYYY-MM-D")}&checkoutDate=${moment(
                        checkoutDate
                      ).format("YYYY-MM-D")}`
                    )}`}
                    target="_blank"
                    className="md:w-full w-fit md:px-0 px-3 flex font-display gap-1 items-center justify-center bg-primary500 text-eadWhite font-bold p-1 rounded-full shadow-sm text-xs"
                  >
                    <WhatsappWhite />
                    <span> Send reservations</span>
                  </Link>
                </div>
              </div>

              <div>
                {/* types */}
                {property?.bedrooms ? (
                  <div className="flex items-center gap-2 overflow-x-scroll no-scrollbar my-4">
                    <PillsTwo
                      title={`${property?.bedrooms ?? 0} Bedroom${
                        (property?.bedrooms ?? 0) > 1 ? "s" : ""
                      }`}
                      icon={getIconComponent("room-icon")}
                    />
                    <PillsTwo
                      title={`${property?.bathrooms ?? 0} Bathroom${
                        (property?.bathrooms ?? 0) > 1 ? "s" : ""
                      }`}
                      icon={getIconComponent("bathroom-icon")}
                    />
                    <PillsTwo
                      title={`${property?.toilets ?? 0} Toilet${
                        (property?.toilets ?? 0) > 1 ? "s" : ""
                      }`}
                      icon={getIconComponent("toilet-icon")}
                    />
                  </div>
                ) : (
                  <SkeletonBar />
                )}

                {/* details info */}
                {property?.description ? (
                  <p className="text-sm font-display font-light text-gray800 dark:text-gray200  mb-2">
                    {property?.description}
                  </p>
                ) : (
                  <SkeletonBar />
                )}

                <hr className="border-gray400 my-3 dark:border-gray800" />
                <p className="text-lg text-gray-900 font-display font-bold dark:text-gray200">
                  Popular amenities
                </p>
                {/* amenities */}
                <div className="flex items-center gap-2 flex-wrap my-3">
                  {property?.property_amenities?.map((item) => (
                    <Pills
                      title={item.amenity.name}
                      icon={getIconComponent(item.amenity.icon_tag)}
                    />
                  ))}
                </div>
                <hr className="border-gray400 my-3 dark:border-gray800 " />

                {/* about host */}
                <HostUserCard
                  name={
                    property?.host?.first_name + " " + property?.host?.last_name
                  }
                  review="4.50 - 4"
                  pic={property?.host?.profile_pic_url}
                />
                <hr className="border-gray400 my-5 dark:border-gray800 " />

                {/* rules */}
                <div className="hidden lg:block">
                  <p className="text-lg font-bold font-display dark:text-gray200 ">
                    Ground Rules
                  </p>
                  <div className="flex items-center gap-4 my-2">
                    {property?.rules?.map((item) => (
                      <p className="text-sm font-display text-gray800 dark:text-gray200 flex items-center gap-2">
                        {item}
                        <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-1/3 ">
              <div className="border border-gray400 rounded-xl p-3 dark:border-gray800 dark:bg-eadBlack">
                <div className="flex gap-1 items-center">
                  <p className="text-2xl font-display font-bold text-gray1000 dark:text-gray200 ">
                    {currencyFormat(property?.price || 0)}
                  </p>
                  <p className="text-xs font-display dark:text-gray200">
                    per night
                  </p>
                </div>
                <div className="flex p-2 border border-gray400 dark:border-gray800 rounded-lg justify-between my-4">
                  <div className=" w-full border-r">
                    <p className="text-sm font-display dark:text-gray200 ">
                      Checkin Time
                    </p>
                    <p className="text-sm text-gray1000 font-display dark:text-gray200 dark:bg-gray1000 font-bold">
                      {property?.check_in_time}
                    </p>
                  </div>
                  <div className=" w-full pl-2">
                    <p className="text-sm font-display dark:text-gray200">
                      Checkout Time
                    </p>
                    <p className="text-sm text-gray1000 dark:bg-gray1000 dark:text-gray200 font-display font-bold">
                      {property?.check_out_time}
                    </p>
                  </div>
                </div>

                <div className="relative">
                  <p className="text-sm font-semibold text-primary500">
                    {warning}
                  </p>
                  <div
                    onClick={() =>
                      setIsDateopen(isDateOpen === "flex" ? "hidden" : "flex")
                    }
                    className="cursor-pointer z-50 flex p-2 border border-gray400 dark:border-gray800 rounded-lg justify-between my-4 flex-shrink-0"
                  >
                    <div className="border-r w-1/2">
                      <p className="text-sm font-display dark:text-gray200 ">
                        Checkin Date
                      </p>
                      <p className="text-sm text-gray1000 dark:text-gray200">
                        {moment(checkinDate).format("DD-MM-yyyy")}
                      </p>
                    </div>
                    <div className="pl-2 w-1/2">
                      <p className="text-sm font-display dark:text-gray200">
                        Checkout Date
                      </p>
                      <p className="text-sm text-gray1000 dark:text-gray200">
                        {moment(checkoutDate).format("DD-MM-yyyy")}
                      </p>
                    </div>
                  </div>
                  <div className={`transition-all ${isDateOpen}`}>
                    <DatePicker
                      minDate={new Date()}
                      filterDate={(date) => !isWeekdayDisabled(date)}
                      excludeDates={customDisabledDates}
                      onChange={onChange}
                      startDate={checkinDate}
                      //@ts-ignore
                      endDate={checkoutDate}
                      selectsRange
                      inline
                      className="font-display"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray1000 font-display dark:text-gray200">
                      Adults
                    </p>
                    <p className="text-xs text-gray700 font-display dark:text-gray200">
                      Age 13+
                    </p>
                  </div>

                  <div className="flex gap-4 items-center mt-3">
                    <button
                      onClick={() =>
                        setCountAdult((prev) => (prev === 0 ? 0 : prev - 1))
                      }
                      className=" p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Minusicon />
                    </button>
                    <div className="py-2 px-4  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray1000">
                      <p className="font-display text-base dark:text-gray200">
                        {countAdult}
                      </p>
                    </div>
                    <button
                      onClick={() =>
                        setCountAdult((prev) =>
                          prev >= property.guests ? prev : prev + 1
                        )
                      }
                      className="p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Plusicon />
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray1000 font-display dark:text-gray200">
                      Children
                    </p>
                    <p className="text-xs text-gray700 font-display dark:text-gray200">
                      Under 12
                    </p>
                  </div>

                  <div className="flex gap-4 items-center mt-3">
                    <button
                      onClick={() =>
                        setCountChildren((prev) => (prev === 0 ? 0 : prev - 1))
                      }
                      className="p-2 rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Minusicon />
                    </button>
                    <div className="py-2 px-4  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray1000">
                      <p className="font-display text-base dark:text-gray200">
                        {countChildren}
                      </p>
                    </div>
                    <button
                      onClick={() => setCountChildren((prev) => prev + 1)}
                      className="p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                    >
                      <Plusicon />
                    </button>
                  </div>
                </div>

                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex items-center justify-between my-3">
                  <p className="font-display text-base text-gray1000 dark:text-gray200">
                    {currencyFormat(property?.price || 0)} * {noOfDays} night
                    {noOfDays > 1 && "s"}
                  </p>
                  <p className="font-display text-base text-gray900 font-medium dark:text-gray200">
                    {currencyFormat(chargeNights || 0)}
                  </p>
                </div>
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex items-center justify-between my-3">
                  <p className="font-display text-base text-gray1000 dark:text-gray200">
                    EAD Booking fee
                  </p>
                  <p className="font-display text-base text-gray900 font-medium dark:text-gray200">
                    {currencyFormat(serviceFee || 0)}
                  </p>
                </div>
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex items-center justify-between my-3">
                  <p className="font-display text-base text-gray1000 font-medium dark:text-gray200">
                    Total
                  </p>
                  <p className="font-display text-base text-gray900 font-bold dark:text-gray200">
                    {currencyFormat(totalAmount || 0)}
                  </p>
                </div>
                <hr className="border-gray400 my-2 dark:border-gray800 md:block hidden" />

                <div className="md:flex hidden gap-2 items-center justify-between my-3">
                  <Link
                    to={`https://wa.me/${
                      process.env.REACT_APP_WHATSAPP
                    }?text=${encodeURIComponent(
                      `Hello i want to book this property ${
                        property?.share_link
                      }?guests=${countAdult}&checkinDate=${moment(
                        checkinDate
                      ).format("YYYY-MM-D")}&checkoutDate=${moment(
                        checkoutDate
                      ).format("YYYY-MM-D")}`
                    )}`}
                    target="_blank"
                    className="w-full flex font-display gap-1 items-center justify-center bg-primary500 text-eadWhite font-bold p-1 rounded-full shadow-sm text-xs"
                  >
                    <WhatsappWhite />
                    <span> Send reservations</span>
                  </Link>
                  <Link
                    to={`mailto:${
                      property?.host?.email
                    }?subject=Bookings&body=${encodeURIComponent(
                      `Hello i want to book this property ${
                        property?.share_link
                      }?guests=${countAdult}&checkinDate=${moment(
                        checkinDate
                      ).format("YYYY-MM-D")}&checkoutDate=${moment(
                        checkoutDate
                      ).format("YYYY-MM-D")}`
                    )}`}
                    className="w-[40%] flex font-display gap-2 items-center justify-center bg-gray400 font-bold p-1 rounded-full shadow-sm text-xs dark:bg-gray1000 dark:text-gray200"
                  >
                    <Emailicon />
                    <span> Email</span>
                  </Link>
                </div>
              </div>

              <div className="border border-gray400 rounded-xl p-3 my-4 dark:border-gray800">
                <div className="flex items-center justify-between mb-2">
                  <p className="text-xl font-bold font-display dark:text-gray200">
                    Location
                  </p>
                  {property?.latitude && property?.longitude ? (
                    <Link
                      to={`https://www.google.com/maps?q=${property?.latitude},${property?.longitude}`}
                      target="_blank"
                      className="text-xs font-display text-primary500"
                    >
                      View in map
                    </Link>
                  ) : (
                    <button
                      disabled
                      className="text-xs font-display text-primary500/50 cursor-not-allowed"
                    >
                      View in map
                    </button>
                  )}
                </div>
                <div>
                  {property?.latitude && property?.longitude ? (
                    <MapView
                      latitude={property?.latitude}
                      longitude={property?.longitude}
                    />
                  ) : (
                    <img src={Maps} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="border-gray400 my-5 dark:border-gray800" />

          {/* similar listing */}
          <div className="hidden lg:block">
            {similarProperties?.length > 0 && (
              <div className="flex items-center justify-between">
                <p className="text-xl font-bold font-display dark:text-gray200">
                  Similar Listings
                </p>
                <div className="flex items-center gap-1">
                  <p className="text-xs font-display dark:text-gray200">
                    See all
                  </p>
                  <ChevronRight />
                </div>
              </div>
            )}

            <div className="flex items-center gap-3">
              {similarProperties?.slice(0, 3).map((item) => (
                <SimilarListing
                  to={`${paths.VIEW_PROPERTY}/${property?.slug}`}
                  pic={item.property_media[0].url}
                  price={currencyFormat(item.price)}
                  title={item.name}
                  description={truncate(item.description, 35)}
                  location={truncate(item.address, 30)}
                  hostname={item.host.first_name + " " + item.host.last_name}
                />
              ))}
            </div>
          </div>

          {/* reservations */}
          <SpecialReservations
            message={`I will like to book ${
              propertyCategories?.subCategories &&
              propertyCategories?.subCategories?.filter(
                (item) => item.id === property.sub_category_id
              )?.[0]?.name
            } with ${property?.bedrooms} Bedroom in ${
              property?.city
            } to stay between ${moment(checkinDate).format(
              "DD-MMM-YYYY"
            )} to ${moment(checkoutDate).format("DD-MMM-YYYY")}`}
          />
        </div>
      </div>

      {/* make bookings */}
      <MakeBookingSection />
      {/* footer */}
      <Footer />
      <Modal
        className="w-[70rem] rounded-xl h-[40rem]"
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <Carousel
          // @ts-ignore
          slides={property?.property_media || []}
          classNameImg="rounded-xl object-contain w-[70rem] h-[40rem]"
        />
      </Modal>
    </div>
  );
};

export default ViewProperty;
